import { useContainer } from "unstated-next";
import DropdownContainer from "../components/DropdownContainer";
import GlobalStore from "../store/global";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const AppOuter = ({ children }) => {
  const { isHamburger, setIsHamburger } = useContainer(GlobalStore);
  const location = useLocation();
  useEffect(() => {
    setIsHamburger(false);
  }, [location.pathname]);
  return (
    <div className="container">
      {isHamburger ? (
        <DropdownContainer hamburgerToggle={setIsHamburger} />
      ) : null}
      <div style={{ marginTop: 70 }}>{children}</div>
      <NavBar hamburgerToggle={setIsHamburger}></NavBar>
      <Footer></Footer>
    </div>
  );
};

export default AppOuter;
