import React, { Component, useEffect } from "react";
import NavBar from "../components/NavBar";
import About from "../components/About";
import Footer from "../components/Footer";
import DropdownContainer from "../components/DropdownContainer";
import "../assets/stylesheets/app.scss";
import { useContainer } from "unstated-next";
import GlobalStore from "../store/global";


const Inner = () => {
  const { isHamburger, setIsHamburger } = useContainer(GlobalStore);
  useEffect(() => {
    setIsHamburger(false);
  }, []);
  return (
    <div className="container">
      {isHamburger ? <DropdownContainer hamburgerToggle={setIsHamburger}/> : null}
      <About></About>
      <NavBar hamburgerToggle={setIsHamburger}></NavBar>
      <Footer></Footer>
    </div>
  );
}

export default class AboutRoute extends Component {
  constructor(props) {
    super(props);
    document.title = "ITR - About";
  }

  render() {
    return (
      <Inner />
    );
  }
}
