import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Awards from "../components/Awards";
import Footer from "../components/Footer";
import "../assets/stylesheets/app.scss";

export default class AboutRoute extends Component {
  constructor(props) {
    super(props);
    document.title = "ITR - Awards";
  }

  render() {
    return (
      <div className="container">
        <Awards></Awards>
        <NavBar></NavBar>
        <Footer></Footer>
      </div>
    );
  }
}
