import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "../assets/stylesheets/dropdown.scss";
import { useSize } from "ahooks";
import { useContainer } from "unstated-next";
import GlobalStore from "../store/global";

const DropdownContainer = () => {
  const container = useRef();
  const { isMobile, toggleMenu } = useContainer(GlobalStore);
  const toggleHamburger = () => {
    container.current.className = "slideOut c";
    setTimeout(() => {
      toggleMenu();
    }, 500);
  };
  return (
    <nav ref={container} className={`dropdown c slideIn ${isMobile ? 'mobile' : ''}`}>
      {!isMobile && (
      <div className="header">
        <label className="header__text">ITR</label>
        <button className="header__x" onClick={() => toggleHamburger()}>X</button>
      </div>)}
      <div className="dd">
        <li>
          <NavLink className="dd__link" to="/">
            <label className="dd__link_label">Home</label>
            <label className="dd__link_arrow">{">"}</label>
          </NavLink>
        </li>
        <li>
          <NavLink className="dd__link" to="/about">
            <label className="dd__link_label">About</label>
            <label className="dd__link_arrow">{">"}</label>
          </NavLink>
        </li>
        <li>
          <NavLink className="dd__link" to="/projects">
            <label className="dd__link_label">Projects</label>
            <label className="dd__link_arrow">{">"}</label>
          </NavLink>
        </li>
        <li>
          <NavLink className="dd__link" to="/gallery">
            <label className="dd__link_label">Gallery</label>
            <label className="dd__link_arrow">{">"}</label>
          </NavLink>
        </li>
      </div>
    </nav>
  );
}

export default DropdownContainer;
