import React, { Component } from "react";
import "../assets/stylesheets/awards.scss";

export default class Awards extends Component {
  render() {
    return (
      <div className="awards">
        <div className="awards__content">
          <h1 className="awards__content__title">Awards & Recognition 🏆</h1>
          <ol className="awards__content__list">
            <i>
              <div className="awards__content__list_item">
                <h2>2019</h2>
              </div>
            </i>
          </ol>
        </div>
      </div>
    );
  }
}
