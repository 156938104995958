import React, { Component } from "react";
import "../assets/stylesheets/footer.scss";
import { ReactComponent as DiscordSVG } from "../assets/svg/discord.svg";
import { ReactComponent as TwitterSVG } from "../assets/svg/twitter.svg";
import { ReactComponent as FacebookSVG } from "../assets/svg/facebook.svg";
import { ReactComponent as InstagramSVG } from "../assets/svg/instagram.svg";
import { ReactComponent as GithubSVG } from "../assets/svg/github.svg";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer__links">
          <ul className="footer__links_ul">
            <li className="footer__links_ul_li">
              <a
                href="https://discord.gg/GA6mvMEzSu"
                target="_blank"
                rel="noreferrer"
                className="footer__links_ul_li_a"
              >
                <DiscordSVG className="footer__links_ul_li_svg"></DiscordSVG>
              </a>
            </li>
            <li className="footer__links_ul_li">
              <a
                href="https://twitter.com/ILTech_Robotics"
                target="_blank"
                rel="noreferrer"
                className="footer__links_ul_li_a"
              >
                <TwitterSVG className="footer__links_ul_li_svg"></TwitterSVG>
              </a>
            </li>
            <li className="footer__links_ul_li">
              <a
                href="https://www.facebook.com/IllinoisTechRobotics/"
                target="_blank"
                rel="noreferrer"
                className="footer__links_ul_li_a"
              >
                <FacebookSVG className="footer__links_ul_li_svg"></FacebookSVG>
              </a>
            </li>
            <li className="footer__links_ul_li">
              <a
                href="https://www.instagram.com/illinoistechrobotics/"
                target="_blank"
                rel="noreferrer"
                className="footer__links_ul_li_a"
              >
                <InstagramSVG className="footer__links_ul_li_svg"></InstagramSVG>
              </a>
            </li>
            <li className="footer__links_ul_li">
              <a
                href="https://github.com/illinoistechrobotics"
                target="_blank"
                rel="noreferrer"
                className="footer__links_ul_li_a"
              >
                <GithubSVG className="footer__links_ul_li_svg"></GithubSVG>
              </a>
            </li>
          </ul>
        </div>

        <div className="footer__toes">
          <ul className="footer__toes__links">
            <li className="footer__toes__links_link">
              <a href="http://localhost:3000/about" className="footer__toes__links_link_a">About</a>
            </li>
            <li className="footer__toes__links_link">
              <a href="http://localhost:3000/contact" className="footer__toes__links_link_a">Contact</a>
            </li>
            <li className="footer__toes__links_link">
              <a href="http://localhost:3000/projects" className="footer__toes__links_link_a">Projects</a>
            </li>
            <li className="footer__toes__links_link">
              <a href="http://localhost:3000/gallery" className="footer__toes__links_link_a">Gallery</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
