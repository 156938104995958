import React, { Component } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../assets/stylesheets/about.scss";

export default class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  click() {}

  render() {
    return (
      <div className="about">
        <div className="about__content">
          <div className="about__content__main">
            <AnimationOnScroll
              animateIn="animate__fadeInDown"
              animateOnce="true"
              duration={1}
              delay={0}
            >
              <div className="about__content__main__container">
                <h1>About Us 📌</h1>
                <span>
                  The Illinois Tech Robotics Club at IIT is a student-run
                  organization dedicated to promoting the development and
                  application of robotics technology. We aim to provide a
                  platform for students to learn, design, build, and compete
                  with robots. Our members come from a variety of disciplines
                  and backgrounds, including computer science, electrical
                  engineering, mechanical engineering, and beyond. We host
                  regular meetings, workshops, and events to encourage hands-on
                  experience and collaboration among members. Our club also
                  participates in various national and international robotics
                  competitions, providing opportunities for members to showcase
                  their skills and put their knowledge to the test. Whether you
                  are a seasoned robotics enthusiast or just starting out, the
                  Illinois Tech Robotics Club is a welcoming community for
                  anyone interested in the field. Join us in exploring the
                  exciting world of robotics and pushing the boundaries of what
                  is possible.
                </span>
              </div>
            </AnimationOnScroll>
          </div>

          <div className="about__content__blocks">
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce="true"
              duration={1}
              delay={500}
            >
              <div className="about__content__blocks_block">
                <h1>Awards 🏆</h1>
                <span>
                  Illinois Tech Robotics has received numerous awards for our
                  contributions to the field of robotics. Our team has been
                  recognized at local competitions for our commitment to
                  innovation and excellence. We take pride in our recognition
                  and continue to push the boundaries of robotics technology.
                </span>
                <p>(Click for more info)</p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce="true"
              duration={1.2}
              delay={500}
            >
              <div className="about__content__blocks_block">
                <h1>Officers 👩‍💼</h1>
                <span>
                  Illinois Tech Robotics is led by a team of student officers
                  committed to advancing robotics. Our diverse officers bring
                  unique skills and perspectives, leading events and projects.
                  We are proud to have a talented and passionate group guiding
                  us forward.
                </span>
                <p>(Click for more info)</p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              animateOnce="true"
              duration={1.4}
              delay={500}
            >
              <div
                className="about__content__blocks_block"
                onClick={this.click}
              >
                <h1>Constitution 📜</h1>
                <span>
                  Illinois Tech Robotics operates under a Constitution outlining
                  our purpose, rules, and processes. It outlines
                  responsibilities, decision-making, and governance to maintain
                  fairness, transparency, and accountability within our team.
                </span>
                <p>(Click for more info)</p>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    );
  }
}
