import { useDebounce, useSize, useThrottleFn } from "ahooks";
import React, { useRef, useEffect, useState } from "react";
import "../../assets/stylesheets/waterfall.scss";

const WaterfallItem = ({ data, onLoad, Render }) => {
  const ref = useRef();
  const size = useSize(ref);
  const sizeRef = useRef({});
  useEffect(() => {
    sizeRef.current.size = size;
  }, [size]);
  return (
    <div className="waterfallItem" ref={ref}>
      <Render
        onLoad={() => {
          setTimeout(() => {
            onLoad(sizeRef.current.size);
          }, 500);
        }}
        sizeRef={sizeRef}
        data={data}
      />
    </div>
  );
};

const useWaterfall = ({ list, columnNumber, Render }) => {
  const stateRef = useRef({
    heights: [],
    loadNum: 0,
  });
  const onload = (index, height) => {
    stateRef.current.heights[index] = height;
    stateRef.current.loadNum++;
    if (stateRef.current.loadNum <= list.length) {
      onSize();
    }
  };
  const [columns, setColumns] = useState([[], [], [], []]);
  const onSize = () => {
    let res = [[], [], [], []];
    let columnHeights = [0, 0, 0, 0];
    rendered.map((renderItem, index) => {
      let minHeight = Infinity;
      let minHeightIndex = 0;
      for (let i = 0; i < columnNumber; ++i) {
        if (minHeight > columnHeights[i]) {
          minHeight = columnHeights[i];
          minHeightIndex = i;
        }
      }
      res[minHeightIndex].push(renderItem);
      columnHeights[minHeightIndex] += stateRef.current.heights[minHeightIndex];
    });
    setColumns(res);
  };
  const waterfallDOM = useRef();
  const size = useSize(waterfallDOM);
  const { run: onSizeThrottle } = useThrottleFn(onSize, { wait: 5e2 });
  useEffect(() => {
    onSizeThrottle();
  }, [size, columnNumber]);
  const rendered = list.map((item, index) => (
    <WaterfallItem
      onLoad={(size) => onload(index, size.height)}
      data={item}
      Render={Render}
    />
  ));
  return {
    waterfallDOM,
    columns,
  };
};

const WaterfallPosition = ({ columnNumber, list, Render }) => {
  const { waterfallDOM, columns } = useWaterfall({
    columnNumber,
    list,
    Render,
  });
  return (
    <div ref={waterfallDOM} className="waterfallContainer">
      {columns.map((column) => {
        return (
          <div
            style={{
              width: `calc(${100 / columnNumber}% - 16px)`,
            }}
          >
            {column}
          </div>
        );
      })}
    </div>
  );
};
export default WaterfallPosition;
