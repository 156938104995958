import AppOuter from "../components/AppOuter";
import Gallery from "../components/Gallery";

const GalleryRoute = () => {
  return (
    <AppOuter>
      <Gallery />
    </AppOuter>
  );
};

export default GalleryRoute;
