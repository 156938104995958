import React, { useEffect, useMemo } from "react";
import "../assets/stylesheets/project.scss";
import { useContainer } from "unstated-next";
import GlobalStore from "../store/global";
import projects from "../assets/data/projects";
import WaterfallPosition from "./hooks/waterfall";
import { Image } from "antd";
const Render = ({ onLoad, data, sizeRef }) => {
  return (
    <div className="project__content__main__container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            fontFamily: "itrFontMedium",
            fontSize: "25px",
            color: "#fff",
            marginBottom: 12,
          }}
        >
          {data.title}
        </h3>
        <h3
          style={{
            fontFamily: "itrFontMedium",
            fontSize: "25px",
            color: "#fff",
            marginBottom: 12,
          }}
        >
          {data.sub}
        </h3>
      </div>
      <div className="project_item_content">
        <Image
          onLoad={() => {
            setTimeout(() => {
              onLoad(sizeRef.current.size);
            }, 500);
          }}
          onError={() =>
            setTimeout(() => {
              onLoad(sizeRef.current.size);
            }, 500)
          }
          width={"100%"}
          src={data.img}
        />
        <span>{data.content}</span>
      </div>
    </div>
  );
};
const Project = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { bodySize } = useContainer(GlobalStore);
  const count = useMemo(() => {
    if (bodySize.width > 850) {
      return 3;
    }
    if (bodySize.width > 650) {
      return 2;
    }
    return 1;
  }, [bodySize]);
  return (
    <div className="project">
      <div className="project__content">
        <div className="project__content__main">
          <div>
            <h1 className="projects__title">Active Projects</h1>
            <WaterfallPosition
              columnNumber={count}
              list={projects.active}
              Render={Render}
            />
          </div>
          <div>
            <h1 className="projects__title">Retired/Inactive Projects</h1>
            <WaterfallPosition
              columnNumber={count}
              list={projects.inactive}
              Render={Render}
            />
          </div>
        </div>
        {/* <WaterfallPositionDemo /> */}
      </div>
    </div>
  );
};
export default Project;
