import React, { Component } from "react";
import "../assets/stylesheets/bubble.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default class Bubble extends Component {
  render() {
    return (
      <div
        // style={{
        //   marginLeft: `${this.props.left}`,
        //   paddingTop: `${this.props.top}`,
        // }}
      >
        <div className="bubble">
          <AnimationOnScroll
            className="bubble_main"
            animateIn="animate__fadeInUp"
            animateOnce="true"
            duration={1}
            delay={0}
          >
            <h1>{this.props.h1}</h1>
          </AnimationOnScroll>
          <AnimationOnScroll
            className="bubble_main"
            animateIn="animate__fadeInUp"
            animateOnce="true"
            duration={1.2}
            delay={0}
          >
            <h2>{this.props.h2}</h2>
          </AnimationOnScroll>
          <AnimationOnScroll
            className="bubble_main"
            animateIn="animate__fadeIn"
            animateOnce="true"
            duration={3}
            delay={500}
          >
            <div>{this.props.children}</div>
          </AnimationOnScroll>
        </div>
      </div>
    );
  }
}
