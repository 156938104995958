import React from "react";
import ReactDOM from "react-dom/client";
import HomeRoute from "./routes/HomeRoute";
import AboutRoute from "./routes/AboutRoute";
import AwardsRoute from "./routes/AwardsRoute";
import ProjectsRoute from "./routes/ProjectsRoute";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorRoute from "./routes/ErrorRoute";
import GlobalStore from "./store/global";
import GalleryRoute from "./routes/GalleryRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeRoute></HomeRoute>,
    errorElement: <ErrorRoute></ErrorRoute>,
  },
  {
    path: "/about",
    element: <AboutRoute></AboutRoute>,
    errorElement: <ErrorRoute></ErrorRoute>,
  },
  {
    path: "/awards",
    element: <AwardsRoute></AwardsRoute>,
    errorElement: <ErrorRoute></ErrorRoute>,
  },
  {
    path: "/projects",
    element: <ProjectsRoute></ProjectsRoute>,
    errorElement: <ErrorRoute></ErrorRoute>,
  },
  {
    path: "/gallery",
    element: <GalleryRoute></GalleryRoute>,
    errorElement: <ErrorRoute></ErrorRoute>,
  },
]);

root.render(
  <GlobalStore.Provider>
    <RouterProvider router={router}></RouterProvider>
  </GlobalStore.Provider>
);

reportWebVitals();
