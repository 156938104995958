import React, { Component, useEffect } from "react";
import NavBar from "../components/NavBar";
import DropdownContainer from "../components/DropdownContainer";
import Home from "../components/Home";
import Footer from "../components/Footer";
import "../assets/stylesheets/app.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContainer } from "unstated-next";
import GlobalStore from "../store/global";


const Inner = () => {
  const { isHamburger, setIsHamburger } = useContainer(GlobalStore);
  useEffect(() => {
    setIsHamburger(false);
  }, []);
  return (
    <div className="container">
      {isHamburger ? <DropdownContainer hamburgerToggle={setIsHamburger}/> : null}
      <Home></Home>
      <NavBar hamburgerToggle={setIsHamburger}></NavBar>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footer></Footer>
    </div>
  );
}

export default class HomeRoute extends Component {
  constructor(props) {
    super(props);
    toast("The new ITR Webpage! 🎉");
    document.title = "ITR - Home";
  }

  render() {
    return (
      <Inner />
    );
  }
}
