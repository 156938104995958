import pic0 from "../pics/gallery/IMG_0796.jpg";
import pic1 from "../pics/gallery/IMG_6390.jpg";
import pic2 from "../pics/gallery/IMG_6392.jpg";
import pic3 from "../pics/gallery/IMG_6393.jpg";
import pic4 from "../pics/gallery/IMG_8547.jpg";
import pic5 from "../pics/gallery/IMG_8548.jpg";
import pic6 from "../pics/gallery/IMG_8549.jpg";
import pic7 from "../pics/gallery/JSDC2011.jpg";
import pic8 from "../pics/gallery/JSDC2012.jpg";
import pic9 from "../pics/gallery/dsc_0001.jpg";
import pic10 from "../pics/gallery/dsc_0003.jpg";
import pic11 from "../pics/gallery/dsc_0235.jpg";
import pic12 from "../pics/gallery/dsc_0238.jpg";
import pic13 from "../pics/gallery/dsc_0243.jpg";
import pic14 from "../pics/gallery/jsdc2015_001.jpg";
import pic15 from "../pics/gallery/jsdc2015_002.jpg";
import pic16 from "../pics/gallery/jsdc2015_003.jpg";

const gallery = {
  pic: [
    {
      title: "",
      content: "",
      img: pic0,
    },
    {
      title: "",
      content: "",
      img: pic1,
    },
    {
      title: "",
      content: "",
      img: pic2,
    },
    {
      title: "",
      content: "",
      img: pic3,
    },
    {
      title: "",
      content: "",
      img: pic4,
    },
    {
      title: "",
      content: "",
      img: pic5,
    },
    {
      title: "",
      content: "",
      img: pic6,
    },
    {
      title: "",
      content: "",
      img: pic7,
    },
    {
      title: "",
      content: "",
      img: pic8,
    },
    {
      title: "",
      content: "",
      img: pic9,
    },
    {
      title: "",
      content: "",
      img: pic10,
    },
    {
      title: "",
      content: "",
      img: pic11,
    },
    {
      title: "",
      content: "",
      img: pic12,
    },
    {
      title: "",
      content: "",
      img: pic13,
    },
    {
      title: "",
      content: "",
      img: pic14,
    },
    {
      title: "",
      content: "",
      img: pic15,
    },
    {
      title: "",
      content: "",
      img: pic16,
    }
  ],
};
export default gallery;
