import { useRouteError } from "react-router-dom";
import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Error from "../components/Error";
import Footer from "../components/Footer";
import DropdownContainer from "../components/DropdownContainer";
import "../assets/stylesheets/app.scss";
import "../assets/stylesheets/error.scss";
import { useContainer } from "unstated-next";
import GlobalStore from "../store/global";

export default function ErrorRoute() {
  const error = useRouteError();
  console.error(error);
  const { isHamburger: hamburger, setIsHamburger: setHamburger } = useContainer(GlobalStore);
  useEffect(() => {
    setHamburger(false);
  }, []);
  // create state for hamburger
  // create function to toggle hamburger
  const hamburgerToggle = () => {
    setHamburger(!hamburger);
  };

  return (
    <div style={{backgroundColor: '#232323'}}>
      <div id="container">
        {hamburger ? (
          <DropdownContainer hamburgerToggle={hamburgerToggle} />
        ) : null}
        <NavBar hamburgerToggle={hamburgerToggle}></NavBar>
        <Error err={{ item: error }}></Error>
        <Footer></Footer>
      </div>
    </div>
  );
}
