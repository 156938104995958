import React, { Component } from "react";
import "../assets/stylesheets/error.scss";

export default class Error extends Component {
  status = this.props.err.item.status;
  statusText = this.props.err.item.statusText;
  message = this.props.err.item.message;

  render() {
    return (
      <div className="c">
        <div className="error">
          <img
            className="error__photo"
            src={require("../assets/pics/error.jpg")}
            alt="background"
          ></img>
        </div>

        <div className="e">
          <img
            className="e__background"
            src={require("../assets/pics/xperror.png")}
            alt="background"
          ></img>
          <div className="e__container">
            <div className="e__container__titleCon">
              <h1 className="e__container__titleCon__title">
                {this.status}: {this.statusText}
              </h1>
            </div>
            <div className="e__container__messageCon">
              <p className="e__container__messageCon__message">If you believe this is unintentional, please contact a site developer!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
