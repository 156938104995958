import { useSize } from 'ahooks';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

const useGlobalStore = () => {
  const bodySize = useSize(document.querySelector('body'));
  const [isMobile, setIsMobile] = useState(false);
  const [isHamburger, setIsHamburger] = useState(false);
  const toggleMenu = () => {
    setIsHamburger(!isHamburger);
  };
  const isScrollLock = isHamburger && isMobile;
  useEffect(() => {
    const isMobile = (bodySize?.width || 1200) < 650;
    setIsMobile(isMobile);
    if (!isMobile) {
      setIsHamburger(false);
    }
  }, [bodySize]);
  useEffect(() => {
    const bodyDom = document.querySelector('body');
    if (isScrollLock) {
      bodyDom.style.height = '100vh';
      bodyDom.style.overflow = 'hidden';
    } else {
      bodyDom.style.height = 'unset';
      bodyDom.style.overflow = 'scroll';
    }
  }, [isScrollLock]);
  return {
    bodySize,
    isMobile,
    isHamburger, toggleMenu, setIsHamburger,
    isScrollLock
  };
};

const GlobalStore = createContainer(useGlobalStore);

export default GlobalStore;
