import fenrir from "../pics/fenrir.jpg";
import roslund from "../pics/roslund.jpg";
import goliath from "../pics/goliath.jpg";
import icarus from "../pics/icarus.jpg";
import mach2 from "../pics/mach2.jpg";
import osiris from "../pics/osiris.jpg";

import Modulus from "../pics/modulus.jpg";
import Roomba from "../pics/roomba.jpg";
import MRK from "../pics/fancypants.jpg";
import Mongol from "../pics/mongol.jpg";
import ma from "../pics/matchlock.jpg";

const projects = {
  active: [
    {
      title: "⏩ Goliath",
      content: `Golaith is designed to effectively compete in the annual MRDC. Goliath is based on a relatively simple drive base consisting of a basic square steel frame, 2 large drive wheels powered by 3HP Ampflow Magmotors and two steel ball casters. The simplicity of this design makes Goliath a very durable and reliable robot that is able to easily withstand encounters with other robots and course obstacles. In addition, this robot was designed such that the center of mass is low to the ground and as close as possible to the drive wheels which, when coupled with its powerful drivetrain, allows it to easily climb and descend relatively steep inclines as well as move very slowly and precisely when necessary. Overall, these design features enable Goliath to reliably perform many competition tasks with minimal impact from any obstacles encountered along the way.`,
      img: goliath,
      sub: "💪"
    },
    {
      title: "⏩ Modulus",
      content: `Modulus uses a crab drive propulsion system and is primarily based off the FIRST robotics kit. A crab drive system consists of a 4 wheel base where each wheel can rotate independently without turning the entire body. This can be very useful for competitions where mobility is important such as MRDC.`,
      img: Modulus,
      sub: "🧠"
    },
    {
      title: "⏩ Roslund",
      content: `Roslund is a simple frame robot with mechanum drive. Mechanum wheels have rollers at a 45° angle to the wheel plane, which allows the robot to have omnidirectional movement. Roslund was the winner of MRDC in 2010 and 2011. Roslund was also awarded the best design award by Grant Imahara (from the Mythbusters) a couple years back.`,
      img: roslund,
      sub: "✨"
    }
  ],
  inactive: [
    {
      title: "⏩ Fenrir",
      content: `Fenrir is a two-wheeled gravity-drive robot. Fenrir's unique design makes it a very fast and agile robot, but also an interesting challenge to control. You can find the current controller code at our github repo: https://github.com/illinoistechrobotics/fenrir.`,
      img: fenrir,
      sub: "🔜"
    },
    {
      title: "⏩ C-Force",
      content: `C-Force is our award winning pumpkin launcher which uses centripetal force to launch pumpkins. A 5HP, 3 phase industrial motor and variable frequency drive is used to spin up the launching arm to approximately 200 RPM and then a second custom designed control system releases the pumpkin on command from a laptop connected via WiFi. There is an optical sensor on the launcher providing input to the controller to signal when the arm is at the proper angle to release the pumpkin. This allows the launcher to consistently throw the pumpkin at the ideal launch angle to acheive the maximum possible distance.`,
      img: mach2,
      sub: "🔜"
    },
    {
      title: "⏩ Icarus",
      content: `Icarus is a quadcopter (a four-rotor helicopter) that is able to lift more weight and is more agile than a standard helicopter. But these benefits come at a cost of stability and require many electronic sensors to maintain stable flight. We are currently working on an RC car to tether to Icarus toact as a manipulator. This way it can pull Icarus close to balls without blowing them away and pick them up. The manipulator will be used during competitions such as MRDC.`,
      img: icarus,
      sub: "🪁"
    },
    {
      title: "⏩ Osiris",
      content: `A full body spinner prototype design with a hexagonal pyramid shell. Used MPU-6050 Gyroscope/Accelerameter package, Yumo/Omron rotary encoders, and I2C communication between Arduino Unos for PID and translational drift control. Required a soldered interface board between Open Source Motor Controller (OSMC) and Arduino Mega consisting of a L7805 voltage regulator, line driver, and optocoupler packages to provide current and signal isolation. Each of 3 Ampflow magmotors has a peak of 4.6 horsepower and draws 150 Amps at full load. Future improvements include fabricating metal body and shell, and integrating the OSMC interface board into a PCB with motor controller components that can operate at 48 V.`,
      img: osiris,
      sub: "🤖"
    },
    {
      title: '⏩ Roombotics',
      content: `Our club received a donation of 30 Roombas. We have decided to build a robotic swarm using them. This swarm will be able to communicate with each other to work together to fulfill a task. Possible tasks that we have thought of include mapping, search and rescue, and playing sports. We are currently using Arduinos to control the Roombas through their on-board commands.`,
      img: Roomba,
      sub: "🧼"
    },
    {
      title: '⏩ Fancy Pants',
      content: `Fancy Pants is a lower extremity exoskeleton and is one of the older projects at ITR. It is currently undergoing a significant redesign with the goals of increasing precision, comfort and safety. Although this exo needs a lot of work before it will be hurling someone 15 feet into the air safely, it has potential for greatness.`,
      img: MRK,
      sub: "🦾"
    },
    {
      title: '⏩ Mongol',
      content: `Mongol is designed to compete in Mech-Warfare. In Mech-Warfare, all robots must walk on 2 or 4 legs as a means of propulsion. Each robot is also equipped with airsoft cannons and impact sensor plates. Each robot is given a certain number of 'hits' and is pitted against competitors in model cityscape to where the object of the competition is to reduce each opponents hits to zero before falling to zero themselves. Meanwhile, though able to be remote controlled, each robot's pilot cannot view the robot directly and must control the robot with visual information coming only from a wireless camera on the robot itself. Mongol was designed entirely in SolidWorks. For more information about Mech-Warfare visit mech-warfare.com`,
      img: Mongol,
      sub: "🖐️"
    }
  ],
};
export default projects;
