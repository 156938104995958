import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../assets/stylesheets/navbar.scss";
import { useContainer } from "unstated-next";
import GlobalStore from "../store/global";
import Hamburger from "hamburger-react";

const NavBar = () => {
  const [navBackground, setNavBackground] = useState({ backgroundColor: "transparent" });
  const { toggleMenu, isHamburger } = useContainer(GlobalStore);
  useEffect(() => {
    document.addEventListener("scroll", (event) => {
      if (window.scrollY < 10) {
        setNavBackground({ backgroundColor: "transparent" });
      }
      else {
        setNavBackground({ backgroundColor: "#232323" });
      };
    });
  }, []);

  return (
    <nav className="navbar" style={navBackground}>
      <a id="logo" href="http://localhost:3000">
        <h2 className="navbar__logotext">ITR</h2>
      </a>
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/projects">Projects</NavLink>
        </li>
        <li>
          <NavLink to="/gallery">Gallery</NavLink>
        </li>
      </ul>
      {/* <NavLink className="nav__login" to="/login">
        Login
      </NavLink> */}
      <div className="nav__placeholder"></div>
      <Hamburger toggled={isHamburger} toggle={toggleMenu} />
      {/* <div className="navbar__hamburger">
        <div className="navbar__hamburger_line"></div>
        <div className="navbar__hamburger_line"></div>
        <div className="navbar__hamburger_line"></div>
      </div> */}
    </nav>
  );
}

export default NavBar;
