import React, { Component } from "react";
import backgroundVideo from "../assets/videos/video.mp4";
import "../assets/stylesheets/home.scss";
import Bubble from "./Bubble";
import * as Scroll from "react-scroll";
import "animate.css";

export default class Home extends Component {
  scrollToContent() {
    var scroll = require("react-scroll");
    scroll = Scroll.animateScroll;
    scroll.scrollTo(920);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="home">
        <div className="home__content">
          <div className="home__content__panel">
            <label className="home__content__panel__title">
              <p>ILLINOIS TECH</p>
              <p>ROBOTICS</p>
            </label>
            {/* <button
              className="home__content__panel__button"
              onClick={this.scrollToContent}
            >
              ⬇️
            </button> */}
            <video
              autoPlay
              playsInline
              controls={false}
              preload="yes"
              loop
              muted
              className="home__content__panel__video"
              type="video/mp4"
              src={backgroundVideo}
            ></video>
          </div>

          <div className="home__content__info">
            <div className="c">
              <Bubble h1="Join us at our" h2="GBMs 🤝">
                <span>
                  Welcome to the Illinois Tech Robotics Club! We are a
                  student-run organization that promotes the field of robotics
                  through hands-on projects, competitions and events. Open to
                  students of all majors and skill levels, we meet every week at
                  6:30pm CST to work on exciting robotics projects.
                </span>
              </Bubble>
              <Bubble
                h1="Directions to the"
                h2="ITR Lab 🗺️"
              >
                <span>
                  1. Enter through the{" "}
                  <a href="https://goo.gl/maps/NJW5LzDDBYoHiD5X6">IIT Tower</a>{" "}
                  located at 10 W 35 St, Chicago, IL.
                  <br />
                  2. Sign in with the front desk, and then take the elevator to
                  the basement.
                  <br />
                  3. Once in the basement, take a right from the elevator and
                  enter the last door on the left.
                  <br />
                  4. Finally, sign in to the Elevate Underground Lab with the
                  CLA on guard!
                </span>
              </Bubble>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
