import React, { useEffect, useMemo } from "react";
import "../assets/stylesheets/gallery.scss";
import { useContainer } from "unstated-next";
import GlobalStore from "../store/global";
import gallery from "../assets/data/gallery";
import WaterfallPosition from "./hooks/waterfall";
import { Image } from "antd";

const Render = ({ onLoad, data, sizeRef }) => {
  return (
    <div className="gallery__content">
      <Image
        onLoad={() => {
          setTimeout(() => {
            onLoad(sizeRef.current.size);
          }, 100);
        }}
        onError={() =>
          setTimeout(() => {
            onLoad(sizeRef.current.size);
          }, 100)
        }
        width={"100%"}
        src={data.img}
      />
    </div>
  );
};

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { bodySize } = useContainer(GlobalStore);
  const count = useMemo(() => {
    if (bodySize.width > 850) {
      return 3;
    }
    if (bodySize.width > 650) {
      return 2;
    }
    return 1;
  }, [bodySize]);
  return (
    <div className="gallery">
      <div className="gallery__content">
        <div className="gallery__content__main">
          <div>
            <h1 className="gallery__title">Gallery</h1>
            <WaterfallPosition
              columnNumber={count}
              list={gallery.pic}
              Render={Render}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gallery;
