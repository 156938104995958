import AppOuter from "../components/AppOuter";
import Projects from "../components/Projects";

const ProjectsRoute = () => {
  return (
    <AppOuter>
      <Projects />
    </AppOuter>
  );
};

export default ProjectsRoute;
